<template>
    <v-container style="margin: 0; padding: 0">
        <v-row>
            <v-col cols="12">
                <v-subheader class="headline primary--text">Projetos</v-subheader>
                 <p-breadcrumbs page="profile.control-panel.project.home" />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-btn color="primary" :to="routeAddProject">
                <span class="black--text">+ Adicionar Obra</span>
            </v-btn>
        </v-row>
        
        <v-row v-if="loading == true">
            <v-col class="subtitle-1 text-center" cols="12">
                Listando as suas obras...
            </v-col>
            <v-col cols="12">
                <v-progress-linear
                    indeterminate
                    rounded
                    height="2"
                ></v-progress-linear>
            </v-col>
        </v-row>

        <v-row v-if="!loading">
            <v-col>
                <v-alert
                    outlined
                    type="warning"
                    prominent
                    border="left"
                    v-if="projects.length == 0"
                >
                    Lamentamos, mas ainda não tem nenhum projecto registado.
                </v-alert>
            </v-col>
        </v-row>
        
        <v-row v-for="project in projects" :key="project.id">
            <v-col cols="12" sm="12" lg="3" >
                <div class="body p-1x" 
                    style="width: 100%; max-width: 100%; height: 100%; max-height: 100%;">
                    <div class="justify-center align-center text-center" >
                            <v-img v-if="project.src" :src="project.src" />
                        
                        <v-card-title class="justify-center align-center">
                            <span> {{ project.title}} </span>
                        </v-card-title>

                        <v-card-subtitle class="text-center">
                            <v-btn :to="project.$route.update" text>
                                <v-icon small class="mr-2">mdi-cog</v-icon>Editar Obra
                            </v-btn>
                        </v-card-subtitle>
                    </div>
                </div>
            </v-col>

            <v-col cols="12" sm="12" lg="9">
                <div class="body p-1x" 
                    style="width: 100%; max-width: 100%;height: 100%; max-height: 100%;">
                    <v-row>
                        <v-col cols="12" md="6">
                            <p class="primary--text">Sinopse</p>
                            <div class="mt-1x" v-html="project.sinopse"></div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="primary--text">Duração</p>
                            <div class="mt-1x" v-html="project.duration"></div>

                            <p class="primary--text mt-2x">Créditos</p>
                            <div class="mt-1x" v-html="project.credits"></div>
                        </v-col>
                    </v-row>
                    <!--v-row>
                        
                        <v-col cols="12" md="6">
                            <p class="primary--text">Data Produção</p>
                            <div class="mt-1x" v-html="project.dataProducaoFmt || 'N/D'"></div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="primary--text">País de Produção</p>
                            <div class="mt-1x" v-html="project.paisProducaoDescricao || 'N/D'"></div>
                        </v-col>
                    </v-row-->
                    <v-row align="end"
                           justify="end" class="m-1x">
                        <v-btn color="primary" :to="project.$route.view" >
                            <span class="black--text">Visualizar Página da Obra</span>
                        </v-btn>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <v-container v-if="hasMore">
            <v-row align="center" justify="center">
                <v-btn 
                :loading="loading" 
                :disabled="loading"
                @click="fetchMore">Carregue Mais</v-btn>
            </v-row>
        </v-container>
    </v-container>
</template>
<script>
import { CONTROL_PANEL,HOME } from '../../../routes';
import { mapState, mapActions } from 'vuex';
export default {
    data: () => ({
        routeAddProject: CONTROL_PANEL.projects.create,
        _loading: false
    }),
    created: async function () {
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
        await this.fetchProjects(this.filter);
    },
    computed: {
        ...mapState('mySpace/project', ['projects', 'loading',  'hasMore']),
        filter() {
            return { 
                limit: 20,
                id: this.$user.info.id,
            }
        }
    },
    methods: {
        ...mapActions('mySpace/project', {
            fetchProjects: 'list',
            loadMore: 'loadMore',
        }),
        fetchMore() {
            this.loadMore(this.filter);
        },

    }
}
</script>